<template>
  <el-menu-item style="color: #ffb000">
    <router-link
      class="navbar-item"
      active-class="avtive"
      :to="{ name: 'Sdcamp' }"
      @keyup.enter.native="handleKeyDownRouter('Sdcamp')"
    >
      {{ $t(`navbar.Sdcamp`) }}
    </router-link>
  </el-menu-item>
</template>

<script>
import { KeyEvents } from '@/Mixins'

export default {
  name: 'RouterSdcamp',
  mixins: [KeyEvents]
}

</script>
