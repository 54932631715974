<template>
  <div class="header-container">
    <a 
      href="#AC"
      title="按下 Enter 鍵，可以跳到主要內容"
      tabindex="1"
      class="sr-only sr-only-focusable"
    >
      跳到主要內容  
    </a>
    <a
      id="AU"
      href="#AU"
      accesskey="U"
      title="頂端功能區塊[快捷鍵Alt+U]"
      tabindex="0"
      class="sr-only sr-only-focusable"
    >
      :::
    </a>
    <!-- desk-view -->
    <div class="header-desk-view">
      <el-row class="header-top">
        <el-menu
          default-active="1"
          mode="horizontal"
          background-color="#000000"
          text-color="#ffffff"
        >
          <el-menu-item>
            <router-link
              class="navbar-item"
              active-class="avtive"
              :to="{ name: 'SiteMap' }"
              @keyup.enter.native="handleKeyDownRouter('SiteMap')"
            >
              {{ $t(`navbar.SiteMap`) }}
            </router-link>
          </el-menu-item>
          <el-menu-item v-for="(item, index) in actionMenuItem" :key="index">
            <router-link
              class="navbar-item"
              active-class="avtive"
              :to="{ name: item.routerName }"
              @keyup.enter.native="handleKeyDownRouter(item.routerName, item.routerHash)"
            >
              {{ $t(`general.${item.displayName}`) }}
            </router-link>
          </el-menu-item>
          <el-menu-item class="language-li">
            <LangSelect />
          </el-menu-item>
        </el-menu>
      </el-row>
      <el-header height="100px">
        <el-row class="header-main">
          <el-menu
            default-active="1"
            mode="horizontal"
            class="header-nav"
            text-color="#6c6c6c"
          >
            <el-menu-item index="1" class="website-title-container">
              <LogoWithLinked />
            </el-menu-item>
            <el-menu-item v-for="(item, index) in menuItem" :key="index">
              <router-link
                class="navbar-item"
                active-class="avtive"
                :to="{ name: item.routerName, hash: item.routerHash }"
                @keyup.enter.native="handleKeyDownRouter(item.routerName, item.routerHash)"
              >
                {{ $t(`navbar.${item.displayName}`) }}
              </router-link>
            </el-menu-item>
            <template v-if="language === 'zh-TW'">
              <RouterSdcamp />
            </template>
          </el-menu>
        </el-row>
      </el-header>
    </div>
    <!-- laptop-pad-phone-view -->
    <div class="header-laptop-pad-phone-view">
      <el-header height="100px">
        <el-row class="header-main">
          <el-col class="header-pad-phone-logo">
            <LogoWithLinked />
          </el-col>
          <!-- 語言切換 -->
          <el-col class="header-pad-phone-lang">
            <LangSelect />
          </el-col>
          <!-- 漢堡 -->
          <el-col class="header-pad-phone-hamburger navbar-menu-hamburger">
            <el-row>
              <el-col :span="12">
                <el-button 
                  class="btn-hamburger"
                  type="text"
                  icon="el-icon-s-fold"
                  plain
                  size="medium"
                  role="button"
                  aria-label="Toggle navigation"
                  style="color: #606266;" 
                  @click="drawer = true"
                />
                <div class="drawer-laptop-view">
                  <el-drawer
                    :visible.sync="drawer"
                    :direction="direction"
                    :modal="false"
                    :with-header="false"
                    custom-class="rwd-menu"
                  >
                    <el-menu 
                      default-active="1" 
                      class="el-menu-vertical-demo"
                      text-color="#606266"
                      active-text-color="#0c356d"
                      mode="vertical"
                      @select="handleSelect"
                    >
                      <el-menu-item v-for="(item, index) in menuItem" :key="index">
                        <router-link
                          class="navbar-item"
                          active-class="avtive"
                          :to="{ name: item.routerName, hash: item.routerHash }"
                        >
                          {{ $t(`navbar.${item.displayName}`) }}
                        </router-link>
                      </el-menu-item>
                      <el-menu-item>
                        <router-link
                          class="navbar-item"
                          active-class="avtive"
                          :to="{ name: 'SiteMap' }"
                        >
                          {{ $t(`navbar.SiteMap`) }}
                        </router-link>
                      </el-menu-item>
                      <template v-if="language === 'zh-TW'">
                        <RouterSdcamp />
                      </template>
                    </el-menu>
                    <el-divider />
                    <el-row class="navbar-action">
                      <el-col v-for="(item, index) in actionMenuItem" :key="index">
                        <router-link
                          class="navbar-item"
                          active-class="avtive"
                          :to="{ name: item.routerName }"
                        >
                          {{ $t(`general.${item.displayName}`) }}
                        </router-link>
                      </el-col>
                    </el-row>
                  </el-drawer>
                </div>
                <div class="drawer-pad-phone-view">
                  <el-drawer
                    id="header-rwd-menu"
                    :visible.sync="drawer"
                    :direction="direction"
                    :modal="false"
                    :with-header="false"
                    :size="'60%'"
                    custom-class="rwd-menu"
                  >
                    <el-menu 
                      default-active="1" 
                      class="el-menu-vertical-demo"
                      text-color="#606266"
                      active-text-color="#0c356d"
                      mode="vertical"
                      @select="handleSelect"
                    >
                      <el-menu-item v-for="(item, index) in menuItem" :key="index">
                        <router-link
                          class="navbar-item"
                          active-class="avtive"
                          :to="{ name: item.routerName, hash: item.routerHash }"
                          @keyup.enter.native="handleKeyDownRouter(item.routerName, item.routerHash)"
                        >
                          {{ $t(`navbar.${item.displayName}`) }}
                        </router-link>
                      </el-menu-item>
                      <el-menu-item>
                        <router-link
                          class="navbar-item"
                          active-class="avtive"
                          :to="{ name: 'SiteMap' }"
                          @keyup.enter.native="handleKeyDownRouter('SiteMap')"
                        >
                          {{ $t(`navbar.SiteMap`) }}
                        </router-link>
                      </el-menu-item>
                      <template v-if="language === 'zh-TW'">
                        <RouterSdcamp />
                      </template>
                    </el-menu>
                    <el-divider />
                    <el-row class="navbar-action">
                      <el-col v-for="(item, index) in actionMenuItem" :key="index">
                        <router-link
                          class="navbar-item"
                          active-class="avtive"
                          :to="{ name: item.routerName }"
                          @keyup.enter.native="handleKeyDownRouter(item.routerName)"
                        >
                          {{ $t(`general.${item.displayName}`) }}
                        </router-link>
                      </el-col>
                    </el-row>
                  </el-drawer>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-header>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { KeyEvents } from '@/Mixins'
import LangSelect from '@/components/LangSelect'
import RouterSdcamp from './components/RouterSdcamp'
import LogoWithLinked from './LogoWithLinked.vue'

export default {
  name: 'LandingHeader',
  components: {
    LangSelect,
    RouterSdcamp,
    LogoWithLinked
  },
  mixins: [KeyEvents],
  data () {
    return {
      textColor: '#ffffff',
      backgroundColor: '',
      backgroundColorHover: '#00273e',
      actionMenuItem: [
        {
          displayName: 'Login',
          routerName: 'Login'
        },
        {
          displayName: 'Apply',
          routerName: 'Register'
        }
      ],
      drawer: false,
      direction: 'rtl'
    }
  },
  computed: {
    ...mapGetters(['language']),
    menuItem() {
      const result = [
        {
          displayName: 'Introduce', // 數據規格介紹
          routerName: 'Landing',
          routerHash: '#introduce'
        },
        {
          displayName: 'UseCase', // 數據公益案例
          routerName: 'Landing',
          routerHash: '#usecase'
        },
        {
          displayName: 'Dataset', // 公益數據集
          routerName: 'Landing',
          routerHash: '#dataset'
        },
        {
          displayName: 'News',
          routerName: 'Landing',
          routerHash: '#news'
        }
      ]
      if (this.language !== 'zh-TW') {
        result.splice(1, 1)
        result.splice(2, 1)
      }
      return result
    }
  },
  methods: {
    handleSelect(key) {
      if (!key) {
        this.activeMenu = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
::v-deep .el-header{
  z-index: 999 !important;
} 
.header-container{
    display: unset;
    
    @media (min-width: 1201px){
      .header-desk-view{
        display: unset;
      }
      .header-laptop-pad-phone-view{
        display: none;
      }
    }
    @media (max-width: 1200px){
      .header-desk-view{
        display: none;
      }
      .header-laptop-pad-phone-view{
        display: unset;
      }

      @media (min-width: 769px){
        .drawer-laptop-view{
          display: unset;
        }
        .drawer-pad-phone-view{
          display: none;
        }
      }
      @media (max-width: 768px){
        .drawer-laptop-view{
          display: none;
        }
        .drawer-pad-phone-view{
          display: unset;
        }
      }
    }
    .header-top{
      background-color: $bg_header_footer;
      color: $text_light;
      display: flex;
      justify-content:flex-end;
      text-align: right;
      padding: 0 1.25rem;

      .el-menu{
        border-bottom:1px solid #000000;

        .el-menu-item:hover {
          font-weight: bold;
          background-color: $hover_dark_blue !important;
        }
      }
      .header-nav{
        display: flex;
        align-items:center;
        .navbar-item{
          text-align: right;
          font-size: 1rem;
          line-height: 60px;
          letter-spacing: 3px;
        }
      }
      .language-li{
        .el-dropdown{
          font-size: 16px;
          color: $text_light;
        }
      }
      
    }
    .el-header{
      box-shadow: 0 4px 2px -2px rgb(160, 160, 160);
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $text_light;
    }
    .header-main{
      display: flex;
      height: inherit;
      ::v-deep .el-menu{
        width: 100%;
      }
      .website-title-container{
        position: absolute;
        left: 0;
      }
      .el-menu{
        width: -webkit-fill-available;
        position: relative;

        .el-menu-item:hover {
          font-weight: bold;
          background-color: $text_light !important;
        }
        .el-menu-item.is-active {
          border-bottom: none;
        }
      }
      .header-nav{
        display: flex;
        justify-content: flex-end;
        align-items:center;
        height: 100px;
        .navbar-item{
          text-align: right;
          font-size: 1rem;
          line-height: 60px;
          letter-spacing: 3px;
        }
        .avtive {
          font-weight: bold;
        }
      }
    }
    .lang-dashboard{
      color: $text_light;
      line-height: 60px;
      cursor: pointer;
      .el-dropdown{
        color: $text_light;
      }
    }
    .header-laptop-pad-phone-view{
      .header-main{
        position: relative; // for image
        align-items: center;
        font-size: 1rem;
        text-align: center;
        @media (max-width: 1200px){
          .header-pad-phone-logo{
            width: 80%;
          }
          .header-pad-phone-lang{
            width: 10%;
          }
          .header-pad-phone-hamburger{
            width: 10%;
          }
        }
        @media (max-width: 576px){
          .header-pad-phone-logo{
            width: 70%;
          }
          .header-pad-phone-lang{
            width: 15%;
          }
          .header-pad-phone-hamburger{
            width: 15%;
          }
        }
      }
      .navbar-menu-hamburger{
        height: 100px;
        line-height: 100px;
        .btn-hamburger{
          border: none;
          background-color: transparent;
        }

        ::v-deep.el-drawer { // 實證環境無效，本地有效
          position: absolute;
          top: 105px;
        }

        .rwd-menu {

          .el-menu .el-menu-item{
            padding: 0;
            font-size: 1rem;
            a{
              display: block;
              width: 100%;
              height: 56px;
            }
          }
          .navbar-action{
            .navbar-item{
              font-size: 1rem;
              color: #606266;
              line-height: 56px;
              height: 56px;
              padding-left: 1.25rem;
              width: 100%;
            }
            a{
              display: block;
              width: 100%;
              height: 56px;
            }
            .navbar-item:hover{
              font-weight: bold;
            }
          }
        }
      }
    }
  }
</style>
