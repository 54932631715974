<template>
  <div class="dashboard-unlogin">
    <Header />
    <el-row v-if="showBreadcrumb" class="breadcrumb-container">
      <Breadcrumb />
    </el-row>
    <router-view />
    <template>
      <Footer />
    </template>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Breadcrumb from '@/components/Breadcrumb'
import 'element-ui/lib/theme-chalk/display.css'
export default {
  name: 'HomePage',
  components: {
    Header,
    Breadcrumb,
    Footer
  },
  data () {
    return {
      showBreadcrumb: false
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  mounted() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      if (this.$route.name === 'Landing') { // 重構的話要加上未登入的判斷
        this.showBreadcrumb = false
      } else {
        this.showBreadcrumb = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/styles/index.scss";
.dashboard-unlogin{
  font-size: 20px;
  letter-spacing: 1px;
  .el-main{
    padding: 0;
  }  
}
</style>

