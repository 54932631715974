<template>
  <el-row class="breadcrumb-container">
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <el-breadcrumb-item>
        <router-link :to="{ name: 'Landing' }">
          <i class="el-icon-s-home" aria-hidden="true" /> 
          {{ $t(`navbar.home`) }}
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{ generateTitle($route.meta.title) }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </el-row>
</template>

<script>
import { generateTitle } from '@/utils/i18n'

export default {
  name: 'BreadcrumbComponent',
  data () {
    return {
      pageName: ''
    }
  },
  watch: {
    $route() {
      this.setBreadcrumbName()
    }
  },
  mounted() {
    this.setBreadcrumbName()
  },
  methods: {
    generateTitle,
    setBreadcrumbName() {
      this.pageName = this.$route.meta.title
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.breadcrumb-container{
    background-color: #f3f5f6;
    padding: 0 3rem;
    .app-breadcrumb.el-breadcrumb{
      line-height: 3.125rem;
      .el-breadcrumb__inner a {
        color: $text_dark;
        &:hover {
          border-bottom: 1px solid $text_title;
        }
      }      
    }
  }
</style>
